import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubCategoryLinks from '../../components/Categories/SubCategoryLinks';
import { categoryUrlSelector } from '../../../modules/selectors/url';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import _ from 'lodash';
import loadable from '@loadable/component';
import ServiceCategories from '../../components/ServiceCategories/ServiceCategories';
import MeisterkachelAndRelatedCategories from '../../components/Meisterkachel/MeisterkachelAndRelatedCategories';
import { InternalLink } from '../../components/form-elements/Link';
import MapSelector from '../../components/Map/MapSelector';
import { standortToMarker } from '../../components/Map/markerHelper';
import { useStandortHistory } from '../../components/VHost/StandortHistory';
import { navigateToSubLocation, selectSubLocation } from '../../../modules/actions/standort/subLocation';
import CalculateRouteLink from '../../components/Map/CalculateRouteLink';
import BreadCrumbSelector from '../../components/BreadCrumb/BreadCrumbSelector';
import ContactRow from '../../components/ContactRow/ContactRow';
import { hideServiceFormSelector } from '../../../modules/selectors/standort/theme';
import LocationCarMakesRow from '../../components/LocationLists/LocationCarMakesRow';

const NotFound = loadable(() => import('../ErrorPages/NotFound/NotFound'));

function AreaSubLocation() {
    const history = useStandortHistory();
    const dispatch = useDispatch();
    const subLocationUrl = useSelector(categoryUrlSelector);
    const markenStandort = useSelector(markenStandortSelector);
    const hideServiceForm = useSelector(hideServiceFormSelector);

    const subLocation = useMemo(() => subLocationUrl && _.find(markenStandort.areaShopSubLocations, { subLocationUrl }), [
        markenStandort.areaShopSubLocations,
        subLocationUrl,
    ]);

    const marker = useMemo(() => {
        const onClick = () => navigateToSubLocation({ history, subLocationUrl: subLocation.subLocationUrl });
        return standortToMarker({ standort: subLocation, onClick });
    }, [history, subLocation]);

    useEffect(() => {
        if (subLocation) {
            dispatch(selectSubLocation({ subLocation }));
        }
    }, [dispatch, subLocation]);

    if (!subLocation) {
        return (
            <>
                <BreadCrumbSelector additionalCrumbs={[{ label: '404', url: `/notFound` }]} />
                <NotFound />
            </>
        );
    }

    return (
        <>
            <BreadCrumbSelector
                additionalCrumbs={[{ label: `${subLocation.anschrift.firma} in ${subLocation.anschrift.ort}`, url: subLocationUrl }]}
            />
            <section className="uk-section uk-section-default uk-section-small">
                <div className="uk-container">
                    <div className="uk-flex uk-flex-between">
                        <h1>
                            {subLocation.anschrift.firma} in {subLocation.anschrift.ort}
                        </h1>
                        <InternalLink to="/">
                            <i className="fa fa-retweet" /> Standort wechseln
                        </InternalLink>
                    </div>
                    <div className="uk-margin-small-bottom">
                        <LocationCarMakesRow location={subLocation} />
                    </div>
                    <div>
                        <div>{subLocation.anschrift.firma}</div>
                        <div>{subLocation.anschrift.strasse}</div>
                        <div>
                            {subLocation.anschrift.plz} {subLocation.anschrift.ort}
                        </div>
                    </div>
                </div>
            </section>
            <section className="uk-section uk-section-default uk-section-small">
                <div className="uk-container">
                    <div className="uk-position-relative uk-margin">
                        <MapSelector markers={[marker]} hideMarkerPosition />
                        <div className="uk-position-bottom-right uk-position-medium">
                            <CalculateRouteLink
                                key={subLocation._id}
                                modifier="secondary"
                                hideArrow
                                label={subLocationUrl}
                                location={subLocationUrl}
                                lng={marker.lng}
                                lat={marker.lat}
                            >
                                <i className="fa fa-location-arrow" /> ROUTE BERECHNEN
                            </CalculateRouteLink>
                        </div>
                    </div>
                </div>
            </section>
            <ContactRow
                location={subLocation}
                additionalActions={
                    <div>
                        <InternalLink className="uk-flex" modifier="primary" hideArrow to="/ueberuns">
                            Über {subLocation.anschrift.firma}
                        </InternalLink>
                    </div>
                }
            />
            {!hideServiceForm && <ServiceCategories />}
            <MeisterkachelAndRelatedCategories />
            <SubCategoryLinks />
        </>
    );
}

export default AreaSubLocation;
