import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import Image from '../../components/Image/Image';
import LocationRows, { carMakesString } from '../../components/LocationLists/LocationRows';
import _ from 'lodash';
import useSWR from 'swr';
import { apiClient } from '../../../helpers/apiHelperV2';
import { useStandortHistory } from '../../components/VHost/StandortHistory';
import { subLocationsToMarkers } from '../../components/Map/markerHelper';
import MapSelector from '../../components/Map/MapSelector';
import BreadCrumbSelector from '../../components/BreadCrumb/BreadCrumbSelector';
import ContactRow from '../../components/ContactRow/ContactRow';
import MoreLocationsModal from './MoreLocationsModal';

function Ueberuns() {
    const markenStandort = useSelector(markenStandortSelector);
    const history = useStandortHistory();

    const { data: areaShopsResponse } = useSWR(`/api/v1/AreaShops?standortUrl=${markenStandort.url}`, async url => {
        const { data } = await apiClient.get(url);
        return data;
    });

    const { data: seitenTexteResponse } = useSWR(`/api/v1/SeitenTexte?standortUrl=${markenStandort.url}`, async url => {
        const { data } = await apiClient.get(url);
        return data;
    });

    const areaShops = useMemo(() => areaShopsResponse?.areaShops ?? [], [areaShopsResponse]);
    const carMakes = useMemo(() => _.uniq(areaShops.flatMap(areaShop => areaShop.allCars)), [areaShops]);
    const sublocationCount = useMemo(() => _.sumBy(areaShops, 'areaShopSubLocations.length'), [areaShops]);
    const seitenTexte = useMemo(() => seitenTexteResponse?.body ?? [], [seitenTexteResponse]);

    const about = (seitenTexte && seitenTexte.find(text => text.name === 'About-Text')) || undefined;

    const markers = useMemo(() => {
        return areaShops.flatMap(areaShop => {
            return subLocationsToMarkers({
                subLocations: areaShop.areaShopSubLocations,
                standortUrl: areaShop.url,
                history,
            });
        });
    }, [areaShops, history]);

    const areaTitlesString = (areaShops = []) =>
        areaShops
            .map(areaShop => areaShop.areaTitle)
            .join(', ')
            .replace(/, ([^,]*)$/, ' und $1');

    return (
        <>
            <BreadCrumbSelector />
            <section className="uk-section uk-section-default uk-section-small">
                <div className="uk-container">
                    <h1>{markenStandort.anschrift.firma}</h1>
                    <div>{markenStandort.slogan}</div>
                    <ul className="uk-list">
                        {false && (
                            <li>
                                <i className="fa fa-check uk-margin-small-right uk-text-primary" />
                                {`Wir betreuen ${sublocationCount} Center.`}
                                {/* in ${areaTitlesString(areaShops)} */}
                            </li>
                        )}
                        <li>
                            <i className="fa fa-check uk-margin-small-right uk-text-primary" />
                            {`Wir führen die ${carMakes.length > 1 ? 'Marken' : 'Marke'} ${carMakesString(carMakes)}.`}
                        </li>

                        <li>
                            <i className="fa fa-check uk-margin-small-right uk-text-primary" />
                            Reparaturen werden ausschließlich mit Originalteilen vom Hersteller durchgeführt.
                        </li>
                    </ul>
                </div>
            </section>
            <section className="uk-section uk-section-default uk-section-small">
                <div className="uk-container">
                    <MapSelector markers={markers} hideMarkerPosition />
                    <LocationRows areaShops={areaShops} />
                </div>
            </section>
            <section className="uk-section uk-section-default uk-section-small">
                <div className="uk-container">
                    <h3>{markenStandort.anschrift.firma}</h3>
                    {about && <div dangerouslySetInnerHTML={{ __html: about.text }} />}
                    <Image
                        className="uk-width-1-1"
                        src={`https://techno-images.s3.eu-central-1.amazonaws.com/standort-images/${markenStandort.gesellschafterNr}/${markenStandort.standortNr}/Matrix1.jpg`}
                        alt=""
                    />
                </div>
            </section>
            <ContactRow location={markenStandort} additionalActions={<MoreLocationsModal areaShops={areaShops} />} />
        </>
    );
}

export default Ueberuns;
